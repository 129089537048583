@tailwind base;
@tailwind components;
@tailwind utilities;


.light-custom-scrollbar {
    scrollbar-color: #fef3c7 transparent;
    scrollbar-width: thin;
}


.dark-custom-scrollbar {
    scrollbar-color: #0f172a transparent !important;
    scrollbar-width: thin !important;
}


.rotate {
    -webkit-animation: rotate 1s normal linear infinite;
    animation: rotate 1s normal linear infinite;
}


.typing svg {
    height: 10px;
    width: 10px;
    margin-right: 2px;
    padding: 0;
}

.typing {
    animation: linear-visibility 1.2s normal infinite;
    overflow: hidden;
    width: 0px;
    opacity: 0;
}

.blink {
    animation: blink 0.8s linear 3;
}


@keyframes linear-visibility {
    0% {
        width: 0px;
        opacity: 0;
    }

    25% {
        width: 12px;
        opacity: 1;
    }

    50% {
        opacity: 1;
        width: 25px;
    }

    75% {
        opacity: 1;
        width: 40px;
    }
}


@keyframes blink {
    0% {
        color: white;
    }

    33% {
        color: black;
        opacity: 0.5;
    }
    
    66% {
        color: black;
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }

    25% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
    }

    50% {
        -webkit-transform: rotate3d(0, 0, 1, 180deg);
        transform: rotate3d(0, 0, 1, 180deg);
    }

    75% {
        -webkit-transform: rotate3d(0, 0, 1, 270deg);
        transform: rotate3d(0, 0, 1, 270deg);
    }

    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #e0cbcb;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #c0a0b9;
    }
}